import React, { memo, useRef, useState } from 'react'
import { css } from '@emotion/react'
import { Button } from '@mui/material'
import { uploadCsv } from '~/modules/tradeSheet/tradingAnalyzer/useTradingSummaryHashResource'
import { useTradingSummaryHashState } from '~/modules/tradeSheet/tradingAnalyzer/useTradingSummaryHashState'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { globalBlueGrey, globalGrey, globalRed } from '~/modules/AppLayout/Colors'
import { fontWeight500, fontWeight600 } from '~/css/font'
import useMedia from '~/hooks/useMedia'
import { RiErrorWarningLine } from 'react-icons/ri'
const UploadDnD = memo(function UploadDnD() {
  const { state, acts } = useTradingSummaryHashState.useContainer()
  const { isPhone, isPc } = useMedia()
  const fileInputRef = useRef() as React.MutableRefObject<HTMLInputElement>
  const [filename, setFilename] = useState<string>('')
  const [concatMessage, setConcatMessage] = useState<string>('')

  const handleUpload = () => {
    if (fileInputRef.current.files?.[0]) {
      const file = fileInputRef.current.files?.[0]
      // 上傳之後取得 hashString，然後在 data layer 觸發 hashString 變動
      uploadCsv(file).then(data => {
        acts.handleHash(data.hashString)
      })
    }
  }

  const handleClear = () => {
    fileInputRef.current.value = ''
    acts.handleHash('')
    setFilename('')
  }

  return (
    <>
      <div
        css={css`
          ${isPhone ? flex.v.allCenter : flex.h.allCenter};
          padding: ${isPhone ? 8 : 32}px;
          margin: ${isPhone ? '0 16px' : '16px 0 0'};
          width: ${isPhone ? 320 : 600}px;
          border: 2px dashed ${globalBlueGrey.bg400};
          position: relative;
          &:hover {
            background-color: ${globalGrey.g100}44;
            cursor: pointer;
          }
        `}
      >
        <p
          css={css`
            font-size: ${isPhone ? 12 : 16}px;
            color: ${globalGrey.g400};
          `}
        >
          {filename ? '' : ' 請選擇檔案'}
        </p>
        <p
          css={css`
            font-size: ${isPhone ? 12 : 16}px;
            ${fontWeight500}
          `}
        >
          {filename}
        </p>
        <input
          onClick={handleClear}
          onChange={() => {
            if (fileInputRef?.current?.files?.length) {
              const file = fileInputRef?.current?.files?.[0]
              if (file.name.split('.')[1] !== 'csv') {
                setConcatMessage('無效檔案類型')
                handleClear()
                return
              }
              setFilename(fileInputRef.current.files[0].name)
              setConcatMessage('')
            }
          }}
          css={css`
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            cursor: pointer;
            opacity: 0;
            &:focus {
              outline: none;
            }
          `}
          ref={fileInputRef}
          type='file'
        />

        <p
          css={css`
            position: absolute;
            bottom: 16px;
            left: 32px;
            font-size: 10px;
            color: ${globalRed.r500};
            ${fontWeight600};
          `}
        >
          {concatMessage}
        </p>
        {filename && state.isDataReady ? (
          <Button
            css={css`
              margin-left: ${isPhone ? '0' : 'auto'};
            `}
            size='small'
            variant='text'
            color='error'
            onClick={handleClear}
          >
            清除資料
          </Button>
        ) : (
          <Button
            css={css`
              margin-left: ${isPhone ? '0' : 'auto'};
            `}
            size='small'
            variant='outlined'
            onClick={handleUpload}
            disabled={fileInputRef?.current?.files?.length === 0}
          >
            產生報表
          </Button>
        )}
      </div>
      <div
        css={css`
          color: ${globalRed.rA400};
          font-size: 10px;
          width: ${isPhone ? 320 : 600}px;
          padding: 12px 0;
          color: ${globalRed.rA400};
          p {
            margin: 0;
            color: ${globalGrey.g700};
          }
        `}
      >
        <p>
          本功能需簽署期貨顧問委任契約才能使用，圖表由華南好神期平台匯出表格而轉換製成，僅提供參考不保證資料準確性。
        </p>
        <div
          css={css`
            ${flex.h.crossCenter};
            gap: 4px;
          `}
        >
          <RiErrorWarningLine />
          客戶上傳之沖銷明細，10分鐘後會自動刪除。
        </div>
      </div>
    </>
  )
})

export default UploadDnD
